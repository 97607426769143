import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { AuthContext } from '../context/auth';

export default function Index() {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    user ? navigate('/app/dashboard') : navigate('/auth/login');
  }),
    [];
  return <div />;
}
